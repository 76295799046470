import React from 'react';

import { Typography, Box, Grid2 as Grid, Container} from '@mui/material';


import Train from './components/Train';
import { OccupancyState } from './components/icons/Occupancy';

const trainData = {
    number: 7624,
    route: 'Stuttgart Hbf - Weilder Stadt'
};


const liveData = {
    station: 'Stuttgart Hbf',
    time: '09:28',
    delay: 0,
    occupancy: 12,
    passengers: 120,
    cars: [
        [ OccupancyState.low, OccupancyState.low, OccupancyState.low, OccupancyState.low ],
        [ OccupancyState.low, OccupancyState.high, OccupancyState.medium, OccupancyState.low ],
        [ OccupancyState.low, OccupancyState.low, OccupancyState.low, OccupancyState.low ],
    ]
}


const prognosedData = [
    {
        station: 'Stuttgart Hbf',
        time: '09:28',
        delay: 0,
        occupancy: 12,
        passengers: 120,
        cars: [
            [ OccupancyState.low, OccupancyState.low, OccupancyState.low, OccupancyState.low ],
            [ OccupancyState.low, OccupancyState.low, OccupancyState.low, OccupancyState.low ],
            [ OccupancyState.low, OccupancyState.low, OccupancyState.low, OccupancyState.low ],
        ]
    },
    {
        station: 'Munich Hbf',
        time: '10:15',
        delay: 5,
        occupancy: 45,
        passengers: 200,
        cars: [
            [ OccupancyState.low, OccupancyState.low, OccupancyState.low, OccupancyState.low ],
            [ OccupancyState.low, OccupancyState.low, OccupancyState.low, OccupancyState.low ],
            [ OccupancyState.low, OccupancyState.low, OccupancyState.low, OccupancyState.low ],
        ]
    },
    {
        station: 'Berlin Hbf',
        time: '11:30',
        delay: 10,
        occupancy: 80,
        passengers: 150,
        cars: [
            [ OccupancyState.high, OccupancyState.low, OccupancyState.low, OccupancyState.low ],
            [ OccupancyState.low, OccupancyState.low, OccupancyState.low, OccupancyState.low ],
            [ OccupancyState.low, OccupancyState.low, OccupancyState.low, OccupancyState.low ],
        ]
    },
    {
        station: 'Hamburg Hbf',
        time: '12:45',
        delay: 0,
        occupancy: 20,
        passengers: 100,
        cars: [
            [ OccupancyState.low, OccupancyState.low, OccupancyState.low, OccupancyState.low ],
            [ OccupancyState.low, OccupancyState.low, OccupancyState.low, OccupancyState.low ],
            [ OccupancyState.low, OccupancyState.low, OccupancyState.low, OccupancyState.low ],
        ]
    },
    {
        station: 'Frankfurt Hbf',
        time: '14:20',
        delay: 15,
        occupancy: 60,
        passengers: 180,
        cars: [
            [ OccupancyState.low, OccupancyState.low, OccupancyState.low, OccupancyState.low ],
            [ OccupancyState.low, OccupancyState.low, OccupancyState.low, OccupancyState.low ],
            [ OccupancyState.low, OccupancyState.low, OccupancyState.low, OccupancyState.low ],
        ]
    },
    {
        station: 'Cologne Hbf',
        time: '15:35',
        delay: 0,
        occupancy: 40,
        passengers: 90,
        cars: [
            [ OccupancyState.low, OccupancyState.low, OccupancyState.low, OccupancyState.low ],
            [ OccupancyState.low, OccupancyState.low, OccupancyState.low, OccupancyState.low ],
            [ OccupancyState.low, OccupancyState.low, OccupancyState.low, OccupancyState.low ],
        ]
    }
];


const LiveView = () => {
    return (
        <Container sx={{ height: 894, width: 710, boxShadow: 3, borderRadius: 2,  margin: "0 auto" }}>
          
          <Grid container spacing={2} sx={{ paddingTop: '34px' }}>
            <Grid size={2}>
                <Typography fontSize={18.5} fontWeight={'bold'} align="left">Live</Typography>
            </Grid>
            <Grid size={3} offset={1}>
                <Typography fontSize={18.5} align="right">
                    <span style={{ fontWeight: 'bold' }}>Zug</span>: {trainData.number}
                </Typography>
            </Grid>
            <Grid size="grow" >
                <Typography fontSize={18.5} align="right">{trainData.route}</Typography>
            </Grid>
          </Grid>

          <Box border="2px solid #dddddd" borderRadius={'3px'} padding={'0px 10px 5px 2px'} marginTop="34px">
             <Train sx={{ paddingTop: "0px" }} cars={liveData.cars} station={liveData.station} passengers={liveData.passengers} time={liveData.time} delay={liveData.delay} occupancy={liveData.occupancy} />
          </Box>

          <Typography fontSize={18.5} fontWeight={'bold'} align="left" marginTop="34px">Prognosen</Typography>

            {prognosedData.map((data, index) => (
                <Train key={index} station={data.station} time={data.time} delay={data.delay} occupancy={data.occupancy} passengers={data.passengers} cars={data.cars} />
                )
              )
            }


        </Container>
    );
};

export default LiveView;