import { SvgIcon } from '@mui/material';

export enum OccupancyState {
    low = 'low',
    medium = 'medium',
    high = 'high',
}

type OccupancyProps = {
    style?: React.CSSProperties;
    state?: OccupancyState;
    mainColor?: string;
};

export const colorMap = {
    [OccupancyState.low]: '#6FAC5B',
    [OccupancyState.medium]: '#FFB600',
    [OccupancyState.high]: '#FD0000',
};

export const occupancySVG = {
    [OccupancyState.low]: (color: string) => (
        <svg width="33" height="21" viewBox="0 0 33 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.67203 10.3467H9.30072C10.7909 10.3467 11.9727 11.5151 11.9727 12.9883V20.1005H0V12.9883C0 11.5151 1.18186 10.3467 2.67203 10.3467Z" fill={color}/>
            <path d="M6.01145 8.21379C8.02637 8.21379 9.6598 6.59893 9.6598 4.6069C9.6598 2.61486 8.02637 1 6.01145 1C3.99652 1 2.3631 2.61486 2.3631 4.6069C2.3631 6.59893 3.99652 8.21379 6.01145 8.21379Z" fill={color}/>
            <path d="M17.1111 8.21379C19.126 8.21379 20.7595 6.59893 20.7595 4.6069C20.7595 2.61486 19.126 1 17.1111 1C15.0962 1 13.4628 2.61486 13.4628 4.6069C13.4628 6.59893 15.0962 8.21379 17.1111 8.21379Z" stroke={color} stroke-miterlimit="10"/>
            <path d="M22.2496 20.1005H21.1191V13.3948C21.1191 12.3279 20.1942 11.4135 19.0123 11.4135H13.7711V10.3467H19.0123C20.8108 10.3467 22.2496 11.7183 22.2496 13.3948V20.1005Z" fill={color}/>
            <path d="M27.337 8.21379C29.3519 8.21379 30.9854 6.59893 30.9854 4.6069C30.9854 2.61486 29.3519 1 27.337 1C25.3221 1 23.6887 2.61486 23.6887 4.6069C23.6887 6.59893 25.3221 8.21379 27.337 8.21379Z" stroke={color} stroke-miterlimit="10"/>
            <path d="M32.1672 20.1005H31.0367V13.3948C31.0367 12.3279 30.1118 11.4135 28.93 11.4135H23.6887V10.3467H28.93C30.7284 10.3467 32.1672 11.7183 32.1672 13.3948V20.1005Z" fill={color}/>
        </svg>
    ),
    [OccupancyState.medium]:  (color: string) => (
        <svg width="33" height="20" viewBox="0 0 33 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.67206 9.34619H9.30084C10.791 9.34619 11.9729 10.5146 11.9729 11.9879V19.1002H0V11.9879C0 10.5146 1.18187 9.34619 2.67206 9.34619Z" fill={color}/>
            <path d="M6.01174 7.21388C8.02669 7.21388 9.66013 5.599 9.66013 3.60694C9.66013 1.61488 8.02669 0 6.01174 0C3.99678 0 2.36334 1.61488 2.36334 3.60694C2.36334 5.599 3.99678 7.21388 6.01174 7.21388Z" fill={color} />
            <path d="M27.3374 7.21388C29.3524 7.21388 30.9858 5.599 30.9858 3.60694C30.9858 1.61488 29.3524 0 27.3374 0C25.3225 0 23.689 1.61488 23.689 3.60694C23.689 5.599 25.3225 7.21388 27.3374 7.21388Z" stroke={color} />
            <path d="M32.1677 19.1002H31.0372V12.3943C31.0372 11.3275 30.1123 10.413 28.9304 10.413H23.689V9.34619H28.9304C30.7289 9.34619 32.1677 10.7178 32.1677 12.3943V19.1002Z" fill={color} />
            <path d="M17.4346 7.21388C19.4495 7.21388 21.083 5.599 21.083 3.60694C21.083 1.61488 19.4495 0 17.4346 0C15.4196 0 13.7862 1.61488 13.7862 3.60694C13.7862 5.599 15.4196 7.21388 17.4346 7.21388Z" fill={color} />
            <path d="M13.7862 19.0487L22.211 19.0996V11.9179C22.211 10.4408 20.5475 9.16748 19.0621 9.16748H13.7862V19.0487Z" fill={color}/>
        </svg>
    ),
    [OccupancyState.high]:  (color: string) => (
        <svg width="31" height="20" viewBox="0 0 31 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.57098 9.36768H8.949C10.3828 9.36768 11.52 10.5387 11.52 12.0152V19.143H0V12.0152C0 10.5387 1.13717 9.36768 2.57098 9.36768Z" fill={color}/>
            <path d="M5.78492 7.22966C7.72365 7.22966 9.2953 5.61125 9.2953 3.61483C9.2953 1.61841 7.72365 0 5.78492 0C3.84619 0 2.27454 1.61841 2.27454 3.61483C2.27454 5.61125 3.84619 7.22966 5.78492 7.22966Z" fill={color}/>
            <path d="M16.3157 7.22966C18.2545 7.22966 19.8261 5.61125 19.8261 3.61483C19.8261 1.61841 18.2545 0 16.3157 0C14.377 0 12.8054 1.61841 12.8054 3.61483C12.8054 5.61125 14.377 7.22966 16.3157 7.22966Z" fill={color}/>
            <path d="M13.646 19.143H21.2601V12.0152C21.2601 10.5387 20.1229 9.36768 18.6891 9.36768H13.646V19.143Z" fill={color}/>
            <path d="M26.303 7.22966C28.2418 7.22966 29.8134 5.61125 29.8134 3.61483C29.8134 1.61841 28.2418 0 26.303 0C24.3643 0 22.7927 1.61841 22.7927 3.61483C22.7927 5.61125 24.3643 7.22966 26.303 7.22966Z" fill={color}/>
            <path d="M23.386 19.143H31V12.0152C31 10.5387 29.8629 9.36768 28.4291 9.36768H23.386V19.143Z" fill={color}/>
        </svg>
    ),
}




const Occupancy = (props: OccupancyProps) => {

    let color = colorMap[props.state || OccupancyState.low];
    if (props.mainColor) {
        color = props.mainColor;
    }

    if (props.state === OccupancyState.high) {
        return (<SvgIcon {...props} >
            <svg width="31" height="20" viewBox="0 0 31 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.57098 9.36768H8.949C10.3828 9.36768 11.52 10.5387 11.52 12.0152V19.143H0V12.0152C0 10.5387 1.13717 9.36768 2.57098 9.36768Z" fill={color}/>
        <path d="M5.78492 7.22966C7.72365 7.22966 9.2953 5.61125 9.2953 3.61483C9.2953 1.61841 7.72365 0 5.78492 0C3.84619 0 2.27454 1.61841 2.27454 3.61483C2.27454 5.61125 3.84619 7.22966 5.78492 7.22966Z" fill={color}/>
        <path d="M16.3157 7.22966C18.2545 7.22966 19.8261 5.61125 19.8261 3.61483C19.8261 1.61841 18.2545 0 16.3157 0C14.377 0 12.8054 1.61841 12.8054 3.61483C12.8054 5.61125 14.377 7.22966 16.3157 7.22966Z" fill={color}/>
        <path d="M13.646 19.143H21.2601V12.0152C21.2601 10.5387 20.1229 9.36768 18.6891 9.36768H13.646V19.143Z" fill={color}/>
        <path d="M26.303 7.22966C28.2418 7.22966 29.8134 5.61125 29.8134 3.61483C29.8134 1.61841 28.2418 0 26.303 0C24.3643 0 22.7927 1.61841 22.7927 3.61483C22.7927 5.61125 24.3643 7.22966 26.303 7.22966Z" fill={color}/>
        <path d="M23.386 19.143H31V12.0152C31 10.5387 29.8629 9.36768 28.4291 9.36768H23.386V19.143Z" fill={color}/>
        </svg>
        </SvgIcon>);
    }
    
    if (props.state === OccupancyState.medium) {
        return (<SvgIcon {...props} >

            <svg width="33" height="20" viewBox="0 0 33 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.67206 9.34619H9.30084C10.791 9.34619 11.9729 10.5146 11.9729 11.9879V19.1002H0V11.9879C0 10.5146 1.18187 9.34619 2.67206 9.34619Z" fill={color}/>
            <path d="M6.01174 7.21388C8.02669 7.21388 9.66013 5.599 9.66013 3.60694C9.66013 1.61488 8.02669 0 6.01174 0C3.99678 0 2.36334 1.61488 2.36334 3.60694C2.36334 5.599 3.99678 7.21388 6.01174 7.21388Z" fill={color} />
            <path d="M27.3374 7.21388C29.3524 7.21388 30.9858 5.599 30.9858 3.60694C30.9858 1.61488 29.3524 0 27.3374 0C25.3225 0 23.689 1.61488 23.689 3.60694C23.689 5.599 25.3225 7.21388 27.3374 7.21388Z" stroke={color} />
            <path d="M32.1677 19.1002H31.0372V12.3943C31.0372 11.3275 30.1123 10.413 28.9304 10.413H23.689V9.34619H28.9304C30.7289 9.34619 32.1677 10.7178 32.1677 12.3943V19.1002Z" fill={color} />
            <path d="M17.4346 7.21388C19.4495 7.21388 21.083 5.599 21.083 3.60694C21.083 1.61488 19.4495 0 17.4346 0C15.4196 0 13.7862 1.61488 13.7862 3.60694C13.7862 5.599 15.4196 7.21388 17.4346 7.21388Z" fill={color} />
            <path d="M13.7862 19.0487L22.211 19.0996V11.9179C22.211 10.4408 20.5475 9.16748 19.0621 9.16748H13.7862V19.0487Z" fill={color}/>
            </svg>
    
            </SvgIcon>);
    }
    
    // Low occupancy by default
    return (<SvgIcon {...props} >
        <svg width="33" height="21" viewBox="0 0 33 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.67203 10.3467H9.30072C10.7909 10.3467 11.9727 11.5151 11.9727 12.9883V20.1005H0V12.9883C0 11.5151 1.18186 10.3467 2.67203 10.3467Z" fill={color}/>
            <path d="M6.01145 8.21379C8.02637 8.21379 9.6598 6.59893 9.6598 4.6069C9.6598 2.61486 8.02637 1 6.01145 1C3.99652 1 2.3631 2.61486 2.3631 4.6069C2.3631 6.59893 3.99652 8.21379 6.01145 8.21379Z" fill={color}/>
            <path d="M17.1111 8.21379C19.126 8.21379 20.7595 6.59893 20.7595 4.6069C20.7595 2.61486 19.126 1 17.1111 1C15.0962 1 13.4628 2.61486 13.4628 4.6069C13.4628 6.59893 15.0962 8.21379 17.1111 8.21379Z" stroke={color} stroke-miterlimit="10"/>
            <path d="M22.2496 20.1005H21.1191V13.3948C21.1191 12.3279 20.1942 11.4135 19.0123 11.4135H13.7711V10.3467H19.0123C20.8108 10.3467 22.2496 11.7183 22.2496 13.3948V20.1005Z" fill={color}/>
            <path d="M27.337 8.21379C29.3519 8.21379 30.9854 6.59893 30.9854 4.6069C30.9854 2.61486 29.3519 1 27.337 1C25.3221 1 23.6887 2.61486 23.6887 4.6069C23.6887 6.59893 25.3221 8.21379 27.337 8.21379Z" stroke={color} stroke-miterlimit="10"/>
            <path d="M32.1672 20.1005H31.0367V13.3948C31.0367 12.3279 30.1118 11.4135 28.93 11.4135H23.6887V10.3467H28.93C30.7284 10.3467 32.1672 11.7183 32.1672 13.3948V20.1005Z" fill={color}/>
        </svg>

        </SvgIcon>);
}

export default Occupancy;