import React from 'react';
import Layout from './Layout';
import Typography from '@mui/material/Typography';
import LiveView from './LiveView';

const App: React.FC = () => {
  return (
    <Layout>

      <LiveView />
      
    </Layout>
  );
};

export default App;