import React from 'react';

import { Typography, Box, Grid2 as Grid, Container, Icon} from '@mui/material';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import Occupancy, { OccupancyState } from './icons/Occupancy';
import Carriage from './Carriage';


function getOccupancyState(occupancy: number): OccupancyState {
    if (occupancy < 30) {
        return OccupancyState.low;
    } else if (occupancy < 70) {
        return OccupancyState.medium;
    } else {
        return OccupancyState.high;
    }
}

type TrainProps = {
    station: string;
    time: string;
    delay: number;
    occupancy: number;
    passengers: number;
    cars: OccupancyState[][];
    sx?: React.CSSProperties;
};


const Train = (props: TrainProps) => {

    const state = getOccupancyState(props.occupancy);

    return (
        <>
          <Grid container spacing={2} sx={{ paddingTop: '34px', minWidth: '622px', ...(props.sx || {}) }}>
                <Grid size={5}>
                    <Typography fontSize={15.5} color='#000000' align="left">
                        <Icon style={{ color: '#757575', position: "relative", top: "6px" }} component={LocationOnIcon} />
                        {props.station}
                    </Typography>
                </Grid>
                <Grid size={3}>
                    <Typography fontSize={15.5} color='#000000' align="left">
                        <Occupancy style={{ position: 'relative', top: '6px', width: '33px' }} state={ state } />
                        {props.passengers} ({props.occupancy}%)
                    </Typography>
                </Grid>
                <Grid size={4} >
                    <Typography fontSize={15.5} color='#000000' align="right">
                        {props.time}
                        <Icon style={{ color: '#757575', position: "relative", top: "6px" }} component={AccessTimeIcon} />
                        + {props.delay} min
                    </Typography>
                </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ minWidth: '622px' }}>
                { props.cars.map((cars, index) => (
                    <Grid size={4} key={index}>
                        <Carriage cars={cars} />
                    </Grid>
                ))}
          </Grid>
        </>
    );
};

export default Train;