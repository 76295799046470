import React from 'react';

import { Typography, Box, Grid2 as Grid, Container, Icon} from '@mui/material';



import { OccupancyState, occupancySVG, colorMap } from './icons/Occupancy';


type CarriageProps = {
    cars: OccupancyState[]
};


const Carriage = (props: CarriageProps) => {
    const color = '#ffffff';

    return (
        <>
            <svg width="214" height="25" viewBox="0 0 214 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                {/* start of train  */}
                <path d="M27.262 17.6367C27.262 19.6104 25.8783 23.7897 24.1757 23.7897H4.02849C0.614587 23.7897 0.149056 21.1647 0.903389 17.6367C1.65772 14.1088 7.16651 4.64495 7.16651 4.64495C8.26137 2.75021 10.6709 1.0874 12.3736 1.0874H24.1886C25.0091 1.09392 25.7941 1.47159 26.3723 2.13806C26.9504 2.80453 27.2749 3.70568 27.2749 4.64495L27.262 17.6367Z" fill="#231F20" stroke="#231F20" stroke-width="0.943742"/>
                
                {/* end of train  */}
                <path d="M185.811 17.6367C185.811 19.6104 187.236 23.7897 188.988 23.7897H209.728C213.242 23.7897 213.722 21.1647 212.945 17.6367C212.169 14.1088 206.498 4.64495 206.498 4.64495C205.371 2.75021 202.89 1.0874 201.138 1.0874H188.975C188.13 1.09392 187.322 1.47159 186.727 2.13806C186.132 2.80453 185.798 3.70568 185.798 4.64495L185.811 17.6367Z" fill="#231F20" stroke="#231F20" stroke-width="0.943742"/>
                
                
                <path d="M58.9417 1.0874H12.4792V21.5668H58.9417V1.0874Z" stroke="#231F20" stroke-width="0.943742"/>
                <path d="M105.403 1.0874H58.9407V21.5668H105.403V1.0874Z" stroke="#231F20" stroke-width="0.943742"/>
                <path d="M151.866 1.0874H105.404V21.5668H151.866V1.0874Z" stroke="#231F20" stroke-width="0.943742"/>
                <path d="M199.409 1.0874H151.866V21.5668H199.409V1.0874Z" stroke="#231F20" stroke-width="0.943742"/>
                
                <path d="M58.6656 1.0874H12.3643V23.7897H58.6656V1.0874Z" stroke="#231F20"/>
                <path d="M105.752 1.0874H58.6656V23.7897H105.752V1.0874Z" stroke="#231F20"/>
                <path d="M152.053 1.0874H105.752V23.7897H152.053V1.0874Z" stroke="#231F20"/>
                <path d="M199.139 1.0874H152.053V23.7897H199.139V1.0874Z" stroke="#231F20" stroke-width="0.943742"/>

                {/* Sector 1 */}
                {props.cars[0] && (<>
                    <path d="M58.6656 1.0874H12.3643V23.7897H58.6656V1.0874Z" fill={colorMap[props.cars[0]]}/>
                    <g width="31" height="20" viewBox="0 0 31 20" fill="none" transform='translate(25, 6) scale(0.645)'>
                        {occupancySVG[props.cars[0]](color)}
                    </g>
                </>)}

                {/* Sector 2 */}
                {props.cars[1] && (<>
                    <path d="M105.752 1.0874H58.6656V23.7897H105.752V1.0874Z" fill={colorMap[props.cars[1]]}/>
                    <g width="31" height="20" viewBox="0 0 31 20" fill="none" transform='translate(72, 6) scale(0.645)'>
                        {occupancySVG[props.cars[1]](color)}
                    </g>
                </>)}

                {/* Sector 3 */}
                {props.cars[2] && (<>
                    <path d="M152.053 1.0874H105.752V23.7897H152.053V1.0874Z" fill={colorMap[props.cars[2]]}/>
                    <g width="31" height="20" viewBox="0 0 31 20" fill="none" transform='translate(119, 6) scale(0.645)'>
                        {occupancySVG[props.cars[2]](color)}
                    </g>
                </>)}

                 {/* Sector 4 */}
                 {props.cars[3] && (<>
                    <path d="M199.139 1.0874H152.053V23.7897H199.139V1.0874Z" fill={colorMap[props.cars[3]]}/>
                    <g width="31" height="20" viewBox="0 0 31 20" fill="none" transform='translate(166, 6) scale(0.645)'>
                        {occupancySVG[props.cars[3]](color)}
                    </g>
                </>)}

            </svg>
        </>
    );
};

export default Carriage;