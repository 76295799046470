import React from 'react';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';

const Topbar: React.FC = () => {
    return (
        <AppBar position="fixed" sx={{ backgroundColor: '#192338' }}>
            <Toolbar sx={{ justifyContent: 'center' }}>
                <Box
                    component="img"
                    sx={{
                        height: 40,
                        marginRight: 2,
                    }}
                    alt="Bahn Stuttgart Logo"
                    src="/logo.png"
                />
            </Toolbar>
        </AppBar>
    );
};

export default Topbar;