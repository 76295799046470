import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Drawer, Toolbar, Typography, Box } from '@mui/material';
import Topbar from './Topbar';

const theme = createTheme();

const drawerWidth = 240;

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Topbar />
            <Box sx={{ display: 'flex' }}>
                <Drawer
                    variant="permanent"
                    
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', bgcolor: '#18364a' },
                    }}
                >
                    <Toolbar />
                    <Box sx={{ overflow: 'auto', margin: '4px', color: '#98a1aa' }}>
                        <Typography variant="h6" noWrap>
                            LIVE TRAINS
                        </Typography>
                    </Box>
                </Drawer>
                <Box
                    component="main"
                    sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, mt: 8 }}
                >
                    <Toolbar />
                    {children}
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default Layout;